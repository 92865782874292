import Image from 'next/image'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub'

import solarImpulseLogo from '../public/assets/images/sif_logo_rgb.svg'
import geLogo from '../public/assets/images/general-electric-alt.svg'
import iMecLogo from '../public/assets/images/imec_logo.svg'
import voxDaleLogo from '../public/assets/images/logo_voxdale.png'
import pmwLogo from '../public/assets/images/pmw-logo-2019.svg'
import kuleuvenLogo from '../public/assets/images/ku_leuven.svg'
import inhollandLogo from '../public/assets/images/inholland.svg'
import linkopingLogo from '../public/assets/images/linkoping_logo.svg'
import airShaperLogo from '../public/assets/images/logo.svg'
import wingtraLogo from '../public/assets/images/wingtra_logo.svg'
import demeLogo from '../public/assets/images/deme_logo.svg'
import apteraLogo from '../public/assets/images/aptera_logo_color.svg'
import redbullLogo from '../public/assets/images/redbull-red.svg'
import mvAgustaLogo from '../public/assets/images/mv_agusta_color.svg'
import decathlonLogo from '../public/assets/images/decathlon_color.svg'
import palVLogo from '../public/assets/images/pal-v-logo-black.svg'
import morganLogo from '../public/assets/images/morgan-motors-logo-black.svg'
import nioLogo from '../public/assets/images/nio_black_logo.svg'

export default function Footer({ showCredits }) {
  return (
    <>
      {showCredits && (
        <>
          <section className="text-center py-5 trusted-by">
            <h3>Trusted By</h3>
            <ul className="text-center pt-3 list-unstyled">
              <li>
                <Link
                  passHref
                  href="https://www.ge.com/renewableenergy/home"
                  target="_blank"
                >
                  <Image
                    src={geLogo}
                    width="50"
                    height="50"
                    alt="General Electric Renewable Energy"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
              <li className="ps-0">
                <Link passHref href="https://www.redbull.com/" target="_blank">
                  <Image
                    src={redbullLogo}
                    width="200"
                    height="40"
                    alt="Deme"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
              <li className="ps-1">
                <Link passHref href="https://www.aptera.us/" target="_blank">
                  <Image
                    src={apteraLogo}
                    width="106"
                    height="61"
                    alt="Aptera"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
              <li className="ps-2">
                <Link
                  passHref
                  href="https://www.decathlon.com/"
                  target="_blank"
                >
                  <Image
                    src={decathlonLogo}
                    width="150"
                    height="37"
                    alt="Decathlon"
                  />
                </Link>
              </li>
              <li className="ps-2">
                <Link passHref href="https://www.nio.com/" target="_blank">
                  <Image
                    src={nioLogo}
                    width="200"
                    height="70"
                    alt="Nio"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
              <li className="ps-1">
                <Link passHref href="https://www.mvagusta.com/" target="_blank">
                  <Image
                    src={mvAgustaLogo}
                    width="120"
                    height="80"
                    alt="MV Agusta"
                  />
                </Link>
              </li>
              <li className="ps-1">
                <Link passHref href="https://wingtra.com/" target="_blank">
                  <Image
                    src={wingtraLogo}
                    width="177"
                    height="41"
                    alt="Wingtra"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
              <li className="ps-1">
                <Link passHref href="https://morgan-motor.com/" target="_blank">
                  <Image
                    src={morganLogo}
                    width="123"
                    height="30"
                    alt="Morgan Motor"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
              <li className="ps-1">
                <Link passHref href="https://pal-v.com/" target="_blank">
                  <Image
                    src={palVLogo}
                    width="123"
                    height="30"
                    alt="Pal-V - World’s First Flying Car"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>

              <li className="ps-1">
                <Link
                  passHref
                  href="https://www.deme-group.com/"
                  target="_blank"
                >
                  <Image
                    src={demeLogo}
                    width="80"
                    height="22"
                    alt="Deme"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
            </ul>
          </section>
          {/*
          <section className="text-center py-5 supported-by">
            <h3>Awards and Support</h3>
            <ul className="text-center pt-3">
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  target="_block"
                  rel="noopener"
                  href="https://solarimpulse.com/efficient-solutions/airshaper"
                >
                  <Image
                    src={solarImpulseLogo}
                    height="100"
                    width="100"
                    alt="Solar Impulse"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </a>
              </li>
              <li>
                <Image
                  src={iMecLogo}
                  width="116"
                  height="35"
                  alt="iMec"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </li>
              <li>
                <Image
                  src={voxDaleLogo}
                  width="160"
                  height="35"
                  alt="Voxdale"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </li>
              <li>
                <Link passHref href="/race-car-aerodynamics">
                  <Image
                    src={pmwLogo}
                    height="120"
                    width="156"
                    className="img-fluid"
                    alt="Professional MotorSport World Awards – MotorSport Technology of the Year"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
              </li>
            </ul>
          </section>
          <section className="text-center py-5 contributions-by">
            <h3>Code contributions by</h3>
            <ul className="text-center pt-3">
              <li>
                <a
                  href="https://www.kuleuven.be/kuleuven/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={kuleuvenLogo}
                    width="112"
                    height="40"
                    alt="KU Leuven"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.inholland.nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={inhollandLogo}
                    width="140"
                    height="40"
                    alt="Inholland"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://liu.se/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={linkopingLogo}
                    height="50"
                    width="138"
                    alt="Linkoping University"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </a>
              </li>
            </ul>
          </section>
          */}
        </>
      )}

      <footer>
        <div className="container d-flex flex-column flex-column-reverse flex-lg-row">
          <div className="logo me-lg-auto align-self-center">
            <Image
              src={airShaperLogo}
              width="188"
              height="50"
              className="align-middle py-5 py-lg-0"
              alt="AirShaper Logo"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </div>
          <div className="links">
            <ul>
              <li className="fw-bold">&nbsp;</li>
              <li>
                <Link href="/blog/page/1">Blog</Link>
              </li>
              <li>
                <Link href="/pricing">Pricing</Link>
              </li>
              <li>
                <Link href="/press">Press</Link>
              </li>
              <li>
                <Link href="/partners">Partners</Link>
              </li>
              <li>
                <Link href="/testimonials">Testimonials</Link>
              </li>
            </ul>
            <ul>
              <li className="fw-bold">Learn</li>
              <li>
                <Link href="/cases">Use Cases</Link>
              </li>
              <li>
                <Link href="/videos/page/1">Videos</Link>
              </li>
              <li>
                <Link href="/videos/collaborations">
                  YouTube Collaborations
                </Link>
              </li>
              <li>
                <Link href="/courses">Courses</Link>
              </li>
              <li>
                <Link href="/research">Research</Link>
              </li>
              <li>
                <Link href="/faq">FAQ</Link>
              </li>
            </ul>
            <ul>
              <li className="fw-bold">Legal</li>
              <li>
                <Link href="/terms-and-conditions">Terms & conditions</Link>
              </li>
              <li>
                <Link href="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link href="/security">Security</Link>
              </li>
            </ul>
            <ul>
              <li className="fw-bold">Social</li>
              <li>
                <FontAwesomeIcon icon={faFacebook} />
                &nbsp;
                <a
                  href="https://www.facebook.com/AirShaper-868711716610088/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faXTwitter} />
                &nbsp;
                <a
                  href="https://x.com/Air_Shaper"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  X
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faLinkedin} />
                &nbsp;
                <a
                  href="https://www.linkedin.com/company/airshaper/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faYoutube} />
                &nbsp;
                <a
                  href="https://www.youtube.com/channel/UCKstw_Eubky6MT02w29YtTA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faGithub} />
                &nbsp;
                <a
                  href="https://github.com/airshaper/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
